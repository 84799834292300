const allCurrencyFormatters = new Map<string, Intl.NumberFormat>()

/**
 * Format a currency value to the fr-FR currency format
 *
 * @export
 * @param {number} value
 * @param {string} [currency='USD']
 * @return {*}  {string}
 */
export function formatCurrency(value: number, currency: string = 'USD', options?: Partial<Intl.NumberFormatOptions>): string {
  // Create a new formatter if it doesn't exist
  const lang = 'fr-FR'
  const formatterHash = JSON.stringify({ lang, currency, options })
  if (!allCurrencyFormatters.has(formatterHash)) {
    allCurrencyFormatters.set(formatterHash, new Intl.NumberFormat(lang, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      currencyDisplay: (currency === 'USD') ? 'narrowSymbol' : 'symbol',
      ...options
    }))
  }

  // Formatting the value
  let formattedValue = allCurrencyFormatters.get(formatterHash)!.format(value)

  // If the value was 0 and the formatted value starts with "-", removing the "-" (without deprecated substr)
  if (value === 0 && formattedValue.startsWith('-')) {
    formattedValue = formattedValue.replace('-', '')
  }

  // If there is a "k" or "M" in the formatted value, removing the space before it
  if (formattedValue.includes("\xa0k")) {
    formattedValue = formattedValue.replace("\xa0k", 'k')
  } else if (formattedValue.includes("\xa0M")) {
    formattedValue = formattedValue.replace('\xa0M', 'M')
  }

  // Return the formatted value
  return formattedValue
}

/**
 * Format a currency value to the fr-FR currency format with two digits
 *
 * @export
 * @param {...Parameters<typeof formatCurrency>} args
 * @return {*}  {ReturnType<typeof formatCurrency>}
 */
export function formatCurrencyWithDigits(...args: Parameters<typeof formatCurrency>): ReturnType<typeof formatCurrency> {
  // Changing the options to force the number of digits to two
  if (!args[2]) {
    args[2] = {}
  }
  args[2].minimumFractionDigits = 2
  args[2].maximumFractionDigits = 2

  // Return the formatted value
  return formatCurrency(...args)
}

/**
 * Convert a currency code to its symbol
 *
 * @export
 * @param {string} currencyCode
 * @return {*}  {string}
 */
export function convertCurrencyCodeToSymbol(currencyCode: string): string {
  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol',
  })
  return formatter.format(0).replace(/0/g, '').replace(',', '').replace(/\s/g, '').trim()
}